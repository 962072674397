<template>
  <div class="webinar-appointment p-xl-3 p-2 bg-white"
       style="border: 1px solid lightgrey; border-radius: 7px;">

    <h3 class="text-center"
        :style="{color: colorPrimary}">
      JETZT KOSTENLOS ANMELDEN
    </h3>
    <div v-if="loading"
         class="text-center mb-4">
      <p class="text-center">
        Verfügbare Webinar-Termine werden geladen
      </p>
      <b-spinner :style="{color: colorPrimary}"
      />
    </div>
    <div v-if="!loading">
      <p class="text-center">
        Wähle einen verfügbaren Webinar-Termin aus, indem Du deinen Wunschtermin anklickst:
      </p>
      <div class="mb-4">
        <b-button v-for="(appointment, index) in appointments"
                  :key="index"
                  block
                  :disabled="appointment.availableSeats === 0"
                  class="px-1 mb-1"
                  style="border-radius: 7px"
                  :style="{background: isSelectedAppointment(appointment) ? colorPrimary : colorSecondary}"
                  @click="appointment.availableSeats > 0 && selectAppointment(appointment)">
          <div class="row no-gutters justify-content-center align-items-center">
            <div class="text-white text-center" style="font-size: 14px">
              {{ `${appointment.day}, ${appointment.date}, ${appointment.time} Uhr` }}
            </div>
            <b-badge :style="{background: getBadgeBackgroundColor(appointment.availableSeats)}"
                     class="text-white ml-2 my-75">
              {{ appointment.availableSeats }} {{ appointment.availableSeats === 1 ? 'Platz frei' : 'Plätze frei' }}
            </b-badge>
          </div>
        </b-button>
      </div>
    </div>

    <div class="mb-3">
      <b-form-input v-model="registerInfo.email"
                    :readonly="noAppointmentsAvailable || loading"
                    @input="clear"
                    placeholder="Deine E-Mail Adresse"
                    type="email"
                    class=""/>
    </div>

    <p class="text-center" style="font-size: 12px">
      Ich möchte am kostenlosen Webinar teilnehmen.
      Ich bin damit einverstanden, dass {{ companyNameText }} mir regelmäßig
      aktuelle Rechts-Updates, Praxistipps und Angebote aus den Bereichen Datenschutz, Abmahnschutz und Internetrecht
      per E-Mail zusendet. Ich kann die
      <b>Einwilligung jederzeit widerrufen.</b>
      Details sind der
      <a :href="dseLink"
         target="_blank"
         :style="{color: colorPrimary}"
         style="text-decoration: underline; font-size: inherit;">Datenschutzerklärung</a>
      zu entnehmen
    </p>

    <div v-if="errorMessage"
         :style="{background: colorDanger}"
         class="alert text-center rounded mb-3 text-white p-3">
      {{ errorMessage }}
    </div>

    <b-button class="text-white mb-2"
              :style="{background: colorSuccess, borderColor: colorSuccess}"
              block
              pill
              size="md"
              :disabled="noAppointmentsAvailable || loading"
              @click="register">
      JETZT KOSTENLOS ANMELDEN
    </b-button>

  </div>
</template>
<script>

import Api from "@/webinar/services/Api";
import {BBadge, BButton, BFormInput, BSpinner} from "bootstrap-vue";

export default {
  name: "WebinarAppointment",
  props: {
    firstName: String,
    lastName: String,
    webinarId: String,
    apiKey: String,
    title: String,
    isPaid: String,
    redirectUrl: String,
    dseLink: String,
    companyNameText: String,
    colorPrimary: {
      type: String,
      default: '#3987ee'
    },
    colorSecondary: {
      type: String,
      default: '#969696'
    },
    colorSuccess: {
      type: String,
      default: '#32bd56'
    },
    colorDanger: {
      type: String,
      default: '#d90d0d'
    },
    colorWarning: {
      type: String,
      default: '#e57112'
    },
  },
  components: {BBadge, BButton, BFormInput, BSpinner},
  data() {
    return {
      loading: true,
      noAppointmentsAvailable: false,
      errorMessage: null,
      successMessage: null,
      appointments: [],
      registerInfo: {
        webinarTitle: this.title,
        appointment: null,
        email: null,
        firstName: this.firstName,
        lastName: this.lastName,
        webinarId: this.webinarId,
        isPaid: this.isPaid
      },
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      Api.appointments({
        webinarId: this.webinarId,
        apiKey: this.apiKey
      }, response => {
        this.loading = false
        this.appointments = response.data.map(appointment => {
          return this.format(appointment)
        })
        let nextAvailableAppointment = this.appointments.find(appointment => appointment.availableSeats > 0)
        if (nextAvailableAppointment) {
          this.selectAppointment(nextAvailableAppointment)
        } else {
          this.noAppointmentsAvailable = true
          this.errorMessage = "Keine verfügbare Termine"
        }
      }, () => {
        this.loading = false
        this.noAppointmentsAvailable = true
        this.errorMessage = "Keine verfügbare Termine"
      })
    },
    register() {
      this.validate()
      if (this.errorMessage) {
        return
      }
      Api.register({
        registerInfo: this.registerInfo,
        apiKey: this.apiKey
      }, () => {
        let dateAndTime = this.registerInfo.appointment.split(" ")
        const redirectUrl = this.redirectUrl
        window.location.replace(`${redirectUrl}?title=${this.registerInfo.webinarTitle}&date=${dateAndTime[0]}&time=${dateAndTime[1]}`)

      }, () => {
        this.errorMessage = 'Anmeldung fehlgeschlagen'
      })
    },
    selectAppointment(appointment) {
      this.registerInfo.appointment = (appointment.date + ' ' + appointment.time)
      this.errorMessage = null
    },
    isSelectedAppointment(appointment) {
      return this.registerInfo.appointment === (appointment.date + ' ' + appointment.time)
    },
    validate() {
      if (!this.registerInfo.email) {
        this.errorMessage = 'Bitte gib Deine E-Mail Adresse an.'
      } else if (!this.isValidEmail(this.registerInfo.email)) {
        this.errorMessage = 'Bitte gib eine gültige E-Mail Adresse an.'
      } else if (!this.registerInfo.appointment) {
        this.errorMessage = 'Bitte wähle einen Termin.'
      }
      return true
    },
    isValidEmail(email) {
      let regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExp.test(email)
    },
    format(appointment) {
      let weekDays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
      let dateAndTime = appointment.SHOWTIME.split(" ")
      let time = dateAndTime[1]
      let date = dateAndTime[0].split(".")
      let dayOfWeek = weekDays[new Date(Number.parseInt(date[0]), Number.parseInt(date[1] - 1), Number.parseInt(date[2])).getDay()]
      let day = date[2]
      let month = date[1]
      let year = date[0]
      return {
        day: dayOfWeek,
        date: day + '.' + month + '.' + year,
        time: time,
        availableSeats: appointment.VFREE
      }
    },
    clear() {
      this.errorMessage = null
    },
    getBadgeBackgroundColor(availableSeats) {
      if (availableSeats === 0) {
        return this.colorDanger
      } else if (availableSeats <= 7) {
        return this.colorWarning
      } else {
        return this.colorSuccess
      }
    }
  }
}
</script>

<style lang="scss">
@import "./styles/theme";

#webinar-appointment {
  font-family: sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>

