import axios from 'axios'

const AXIOS = axios.create({
    // baseURL: "https://app.prive.eu",
    baseURL: "https://prive-datagap.app.bitingbit.cloud",
    // baseURL: "http://localhost:8081",
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    appointments: (query, success, failure) => {
        AXIOS.post(`/public-api/webinaris/appointments/${query.webinarId}/${query.apiKey}`)
            .then(success)
            .catch(failure)
    },
    register: (query, success, failure) => {
        AXIOS.post(`/public-api/webinaris/register/${query.apiKey}`, query.registerInfo)
            .then(success)
            .catch(failure)
    },
}
